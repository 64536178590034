import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import {
  GrayContainer,
  LineWrapperWithBottomSpace,
} from "../global/common/containers"
import { CenterTitle, SecondaryTitle } from "../global/common/text"
import { Square, greenBox } from "../global/common/ornaments"
import { Box, UnstyledBoxes } from "../case-study/common/boxes"

const BoxesContent = styled(UnstyledBoxes)`
  @media ${minD.tablet} {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${minD.laptop} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const StyledBox = styled(Box)`
  padding: 2.5rem;

  @media ${minD.laptop} {
    &:nth-child(4),
    &:nth-child(5) {
      background-color: var(--faded-green);
    }

    &:nth-child(6) {
      grid-column: 3 / span 1;
    }
  }

  @media ${minD.mobileL} {
    padding: 2.5rem 30px;
  }
`

const DarkBoxContainer = styled(Square)`
  position: relative;

  @media ${minD.laptop} {
    top: 50%;
  }

  @media ${maxD.laptop} {
    display: none;
  }
`

const DarkBox = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 3rem;
  background-color: var(--dark-gray);

  ${greenBox}

  &::after {
    right: 20px;
    bottom: -45px;
  }
`

const DarkBoxTitle = styled(SecondaryTitle)`
  color: #fff;
  text-align: center;
`

const WaysOfUsing = ({ data }) => {
  const { waysTitle: title, waysList: list, grayBoxHeader } = data

  return (
    <GrayContainer>
      <LineWrapperWithBottomSpace>
        <CenterTitle dangerouslySetInnerHTML={{ __html: title }} />
        <BoxesContent>
          {list &&
            list.map(
              ({ header: boxTitle, fieldGroupName: name, description }, i) => (
                <>
                  {i !== 1 ? (
                    <StyledBox key={`${name}-${i}`}>
                      <SecondaryTitle>{boxTitle}</SecondaryTitle>
                      <p dangerouslySetInnerHTML={{ __html: description }} />
                    </StyledBox>
                  ) : (
                    <DarkBoxContainer as="div">
                      <DarkBox className="full">
                        <DarkBoxTitle
                          dangerouslySetInnerHTML={{ __html: grayBoxHeader ? grayBoxHeader : title }}
                          style={{ "--theme-color": "var(--orange)" }}
                        ></DarkBoxTitle>
                      </DarkBox>
                    </DarkBoxContainer>
                  )}
                </>
              )
            )}
        </BoxesContent>
      </LineWrapperWithBottomSpace>
    </GrayContainer>
  )
}

export default WaysOfUsing
