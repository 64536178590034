import React from "react"

import MasonryBoxes from "../global/atoms/MasonryBoxes"
import SectionWithIndentedTitle from "../global/SectionWithIndentedTitle"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const Reasons = ({ data }) => {
  const title = data.indentedSectionTitle

  const image = {
    url: getImageBySrcSetAndSize(data.indentedSectionImage?.srcSet, 'medium') ? getImageBySrcSetAndSize(data.indentedSectionImage?.srcSet, 'medium') : optimizedPath(data.indentedSectionImage?.sourceUrl),
    srcSet: getRangedSrcSet(data.indentedSectionImage?.srcSet, 'medium'),
    alt: (SiteLanguage() !== "en" && data.indentedSectionImage?.atttachement_pl?.altTextPl) ? data.indentedSectionImage?.atttachement_pl?.altTextPl : data.indentedSectionImage?.altText
  }

  const button = {
    title: data.indentedSectionButton?.title,
    url: data.indentedSectionButton?.url,
    target: data.indentedSectionButton?.target,
  }

  const boxes = data.reasonsList

  return (
    <SectionWithIndentedTitle title={title} image={image}>
      <MasonryBoxes
        boxes={boxes}
        button={button}
        boxStyles={{ "--box-background-color": "#fff" }}
      />
    </SectionWithIndentedTitle>
  )
}

export default Reasons
