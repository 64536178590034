import React from "react"

import List from "../global/List"

const HowDoesItWork = ({
  data: { howDoesItWorkTitle: title, howDoesItWorkList: list },
}) => {
  return <List title={title} list={list} />
}

export default HowDoesItWork
