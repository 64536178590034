import React from "react"
import styled from "styled-components"

import LiteYouTubeEmbed from "react-lite-youtube-embed"

import { minD, betweenD, maxD } from "../../assets/styles/helpers/devices"

import { MainTitle, SecondaryTitle } from "../global/common/text"
import { Signature } from "../global/atoms/Signature"

import { WhyUsLeftContentWrapper } from "../global/atoms/why-us-movie/Wrapper"
import { WhyUsContent } from "../global/atoms/why-us-movie/Content"
import { WhyUsSubtitle } from "../global/atoms/why-us-movie/Subtitle"
import { WhyUsMovie } from "../global/atoms/why-us-movie/Movie"
import { WhyUsMovieContainer } from "../global/atoms/why-us-movie/MovieContainer"
import { WhyUsSignatureContainer } from "../global/atoms/why-us-movie/SignatureContainer"
import { WhyUsLogo } from "../global/atoms/why-us-movie/Logo"

const WhyUsCustomContent = styled(WhyUsContent)`
  @media ${betweenD.tabletLLaptop} {
    padding-right: 120px;
  }

  @media ${minD.laptop} {
    padding-left: 90px;
  }
`

const WhyUsMovieCustomContainer = styled(WhyUsMovieContainer)`
  &::after {
    left: 20px;

    @media ${maxD.tablet} {
      left: -40px;
    }
  }

  &::before {
    right: -25px;
  }
`

const WhyUs = ({ data }) => {
  const {
    whyUsTitle: title,
    whyUsTextBelowTitle: textUnderTheTitle,
    whyUsSubtitle: subTitle,
    whyUsTextBelowSubtitle: description,
    offerWhyUsMovie: movieID,
    testimonialAuthorName: authorName,
    testimonialAuthorPosition: authorPosition,
    testimonialAuthorLogo: logoObject,
  } = data

  const logo = {
    url: logoObject?.localFile.publicURL,
    alt: logoObject?.altText,
  }

  return (
    <WhyUsLeftContentWrapper flex alignItems="fs">
      <WhyUsMovie>
        <WhyUsMovieCustomContainer>
          <LiteYouTubeEmbed
            id={movieID}
            poster="maxresdefault"
            title="Client words on video"
            noCookie={true}
          />
        </WhyUsMovieCustomContainer>
        <WhyUsSignatureContainer>
          <Signature
            name={authorName}
            position={authorPosition}
            style={{ "--padding-mobile": "2.5rem 0 " }}
          />
          {logo && <WhyUsLogo src={logo.url} alt={logo.alt} />}
        </WhyUsSignatureContainer>
      </WhyUsMovie>
      <WhyUsCustomContent>
        <MainTitle dangerouslySetInnerHTML={{ __html: title }} />
        <WhyUsSubtitle
          dangerouslySetInnerHTML={{ __html: textUnderTheTitle }}
        />
        <SecondaryTitle
          dangerouslySetInnerHTML={{ __html: subTitle }}
          as="h3"
        />
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </WhyUsCustomContent>
    </WhyUsLeftContentWrapper>
  )
}

export default WhyUs
